.dashboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.dashboardContainer h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 1rem;
}

.dashboardContainer button {
  /* width: 100%; */
  height: 3rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #000;
  color: #fff;
  font-size: medium;
  margin-left: 1rem;
}

.dashboardContainer input {
  width: 20%;
  height: 2rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: black;
  font-size: medium;
  margin-top: 1rem;
  margin-left: 2rem;
  box-shadow: #f5f5f5;
  text-align: center;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.btnContainer button {
  margin: 2rem;
}

/* .btnContainer button:hover {
  background-color: #fff;
  color: #000;
  cursor: pointer;
  border: #000;
} */

.txtContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.txtContainer input {
  width: 20%;
  height: 2rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: black;
  font-size: medium;
  margin-top: 1rem;
  margin-left: 2rem;
  box-shadow: #f5f5f5;
  text-align: center;
}

.txtContainer button {
  margin: 2rem;
}

.txtContainer p {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 1rem;
  margin-left: 2rem;
  background-color: #f5f5f5;
}

.graphButton {
  /* width: 100%; */
  height: 3rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #000;
  color: #fff;
  font-size: medium;
  margin-left: 1rem;
}

.graphButton:hover {
  background-color: #fff;
  color: #000;
  cursor: pointer;
  border: #000;
}

.graphButton_active {
  /* width: 100%; */
  height: 3rem;
  border-radius: 0.5rem;
  background-color: grey !important;
  font-size: medium;
  margin-left: 1rem;
}
